import React, {useEffect, useState} from "react";
import HeaderContent from "./HeaderContent";
import Sidebar from "./Sidebar";
import '../style/header-mobile.scss';
import {
    trackHeaderBurgerButtonClicked,
    trackHeaderLogoClicked
} from "../../../main/utility/analytics";
import {getRouterPrefix} from "../../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../../main/constants/BASE_ROUTERS";
import {useLocation} from "react-router-dom";

function HeaderMobile({isVisible}) {

    const safeDocument = typeof document !== 'undefined' ? document : {};
    const { body } = safeDocument;
    const location = useLocation()

    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
        body.style.overflow = sidebar ? 'hidden' : 'scroll';
    }, [sidebar]);

    function showSidebar() {
        setSidebar(true)
        burgerButtonClickedHandler()
    }

    function hideSidebar() {
        setSidebar(false)
    }

    function logoClickHandler() {
        trackHeaderLogoClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        );
    }

    function burgerButtonClickedHandler() {
        trackHeaderBurgerButtonClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        );
    }

    return (
        <section className={"header-mobile"}>
            <HeaderContent isVisible={isVisible} showSidebar={showSidebar} logoClickHandler={logoClickHandler}/>
            <Sidebar sidebar={sidebar} showSidebar={showSidebar} hideSidebar={hideSidebar}/>
        </section>
    );
}

export default HeaderMobile