import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import HeaderDesktop from "./desktop/HeaderDesktop";
import HeaderMobile from "./mobile/HeaderMobile";
import {useScreenSize} from "../../main/providers/ScreenSizeProvider";
import {SCREEN_SIZES} from "../../main/constants/SCREEN_SIZES";
import './style/header.scss';

function Header({ shouldHideOnScroll = true }) {

    const { isSmallerThan } = useScreenSize();
    const location = useLocation()

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        let lastScrollY = window.scrollY;

        function scrollHandler() {
            if (!shouldHideOnScroll) {
                setIsVisible(true);
                return;
            }

            const currentScrollY = window.scrollY;
            setIsVisible(currentScrollY <= lastScrollY || currentScrollY <= 30);
            lastScrollY = currentScrollY;
        }

        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    }, []);

    useEffect(() => {
        scrollToTop()
    }, [location]);

    function scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <>
            {isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                <HeaderMobile isVisible={isVisible}/>
            ) : (
                <HeaderDesktop isVisible={isVisible}/>
            )}
        </>
    )

}

export default Header