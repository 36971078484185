import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { scrollToSection } from "../../../main/utility/utility";
import { getRouterPrefix } from "../../router/getRouterPrefix";
import { BASE_ROUTERS } from "../../../main/constants/BASE_ROUTERS";
import { BASE_IMAGES } from "../../../main/constants/BASE_IMAGES";
import { useTranslation } from "react-i18next";
import { SECTION } from "../../../main/constants/SECTION";
import '../style/header-desktop.scss';
import {
    trackAboutUsClicked,
    trackContactUsClicked, trackHeaderLogoClicked,
    trackHowToOrderClicked,
    trackServiceClicked
} from "../../../main/utility/analytics";

function HeaderDesktop({ isVisible }) {

    const { t } = useTranslation();
    const location = useLocation();

    function handleSectionClick(e, section) {
        if (location.pathname === getRouterPrefix() + BASE_ROUTERS.main) {
            e.preventDefault();
            scrollToSection(section.id);
            trackAnalytics(section)
        } else {
            trackAnalytics(section, location.pathname)
        }
    }

    function trackAnalytics(section, screen = "Home Screen") {
        switch (section) {
            case SECTION.aboutUs:
                trackAboutUsClicked(screen);
                break;
            case SECTION.eventCategories:
                trackServiceClicked(screen);
                break;
            case SECTION.howToOrder:
                trackHowToOrderClicked(screen);
                break;
            default:
                break
        }
    }

    function logoClickHandler() {
        trackHeaderLogoClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        );
    }

    function contactUsClickedHandler() {
        scrollToSection(SECTION.contactUs.id)
        trackContactUsClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        );
    }

    return (
        <>
            <header className={`header-desktop ${isVisible ? 'header-visible' : 'header-hidden'}`}>
                <div className="section">
                    <hr className="line" />

                    <NavLink
                        to={getRouterPrefix() +BASE_ROUTERS.aboutUs}
                        className="category"
                        onClick={(e) => handleSectionClick(e, SECTION.aboutUs)}
                    >
                        <h2>{t(SECTION.aboutUs.title)}</h2>
                    </NavLink>

                    <NavLink
                        to={getRouterPrefix() + BASE_ROUTERS.eventCategories}
                        className="category"
                        onClick={(e) => handleSectionClick(e, SECTION.eventCategories)}
                    >
                        <h2>{t(SECTION.eventCategories.title)}</h2>
                    </NavLink>
                </div>

                <NavLink className="logo"
                         to={getRouterPrefix() + BASE_ROUTERS.main}
                         onClick={logoClickHandler}>
                    <img src={BASE_IMAGES.logoIcon} className="logo-icon" alt="Logo" />
                </NavLink>

                <div className="section">
                    <NavLink
                        to={getRouterPrefix() + BASE_ROUTERS.howToOrder}
                        className="category"
                        onClick={(e) => handleSectionClick(e, SECTION.howToOrder)}
                    >
                        <h2>{t(SECTION.howToOrder.title)}</h2>
                    </NavLink>

                    <span className={"category"} onClick={contactUsClickedHandler}>
                        <h2>{t(SECTION.contactUs.title)}</h2>
                    </span>

                    <hr className="line" />
                </div>
            </header>
            <div className="header-height" />
        </>
    );
}

export default HeaderDesktop;
