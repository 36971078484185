import React from "react";
import {Link} from "react-router-dom";
import {getRouterPrefix} from "../../router/getRouterPrefix";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import {BASE_ROUTERS} from "../../../main/constants/BASE_ROUTERS";

const HeaderContent = ({ isVisible, logoClickHandler, showSidebar }) => {

    return (
        <div className={`content ${isVisible ? 'header-visible' : 'header-hidden'}`}>
            <Link className="header-logo"
                  to={getRouterPrefix() + BASE_ROUTERS.main}
                  onClick={logoClickHandler}>
                <img className={"icon"} src={BASE_IMAGES.logoIcon} alt="" title="Nav Icon"/>
            </Link>

            <div className={"burger-icon-container"} onClick={showSidebar}>
                <img className={"burger-icon"} src={BASE_IMAGES.burgerIcon} alt="" title="Nav Icon"/>
            </div>
        </div>
    );
};

export default HeaderContent;
