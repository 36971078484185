import {sendEmailVerificationCode} from "../../../../api/endpoints/auth/sendEmailVerificationCode";
import {toast} from "react-toastify";

export const sendVerificationCodeRequest = async (
    email,
    successHandler = () => {},
    finallyHandler = () => {}
) => {
    try {
        const verificationData = await sendEmailVerificationCode(email);

        if (!verificationData.success) {
            const errorMessage = verificationData.messages.length > 0 ? verificationData.messages[0].value : "Unknown error";
            toast.error(errorMessage);
            return;
        }

        console.log(verificationData)
        successHandler()
    } catch (error) {
        console.log(error)
        toast.error("Failed to send the code, please try again later");
    } finally {
        finallyHandler()
    }
}