import {useTranslation} from "react-i18next";
import FooterPaymentSection from "./sections/FooterPaymentSection";
import {Link, useLocation} from "react-router-dom";
import {getRouterPrefix} from "../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../main/constants/BASE_ROUTERS";
import {BASE_IMAGES} from "../../main/constants/BASE_IMAGES";
import FooterContactsSection from "./sections/FooterContactsSection";
import {trackFooterLogoClicked} from "../../main/utility/analytics";

function FooterMobile({ socialMedia }) {

    const { t } = useTranslation()
    const location = useLocation()

    function logoClickedHandler() {
        trackFooterLogoClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        )
    }

    return (
        <>
            <div className="footer-container">

                <Link className="footer-logo"
                      to={getRouterPrefix() + BASE_ROUTERS.main}
                      onClick={logoClickedHandler}>
                    <img src={BASE_IMAGES.logoIcon} className={"footer-logo-icon"}/>
                </Link>

                <FooterPaymentSection/>

                <FooterContactsSection socialMedia={socialMedia}/>

            </div>

            <div className={"footer-copyright"}>
                <p>{t("copyright-info")}</p>
            </div>
        </>
    )
}

export default FooterMobile