import { useEffect, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { toast } from "react-toastify";
import {useLoading} from "../../../../../main/providers/LoadingProvider";
import {verifyEmailCode} from "../../../../../api/endpoints/auth/verifyEmailCode";
import {LocalDatabase} from "../../../../../api/database/LocalDatabase";
import {BASE_ROUTERS} from "../../../../../main/constants/BASE_ROUTERS";
import {sendVerificationCodeRequest} from "../../custom/handlers";
import ConfirmCodePresenter from "./ConfirmCodePresenter";
import {registerUser} from "../../../../../api/endpoints/auth/registerUser";
import Header from "../../../../header/Header";
import Footer from "../../../../footer/Footer";
import {validateCode} from "../../../../../api/endpoints/auth/reset-password/validateCode";

function ConfirmCodeContainer({ isRegisterFlow }) {

    return (
        <div className="confirm-code-container">
            <Header shouldHideOnScroll={false}/>
            <Content isRegisterFlow={isRegisterFlow}/>
            <Footer isAccountButtonShown={false}/>
        </div>
    )
}

function Content({ isRegisterFlow }) {

    const [formState, setFormState] = useState({ code: "", error: "", touched: false });
    const { showLoading, hideLoading } = useLoading();
    const { name, email, password } = useLocation().state || {};
    const navigate = useNavigate();

    useEffect(() => {
        if (formState.touched) validateForm();
    }, [formState.code]);

    const validateForm = () => {
        const error = formState.code.length === 0 ? "Code is required" : "";
        setFormState((prev) => ({ ...prev, error }));
        return !error;
    };

    const handleVerificationRequest = async (email, code) => {
        try {
            const verificationData = await verifyEmailCode(email, code);
            if (!verificationData.success) {
                toast.error(verificationData.messages[0]?.value || "Verification failed");
                return false;
            }
            return true;
        } catch {
            toast.error("Verification failed. Try again later.");
            return false;
        }
    };

    const handleRegistrationRequest = async (name, email, password, navigate) => {
        try {
            const registerData = await registerUser(name, email, password);
            if (!registerData.success) {
                toast.error(registerData.messages[0]?.value || "Registration failed");
                return false;
            }
            LocalDatabase.setToken(registerData.data.token);
            toast.success("Registration successful");
            navigate(BASE_ROUTERS.designsSales);
            return true;
        } catch {
            toast.error("Something went wrong during registration. Please try again later.");
            return false;
        }
    };

    const handleSendVerificationCodeRequest = async () => {
        showLoading();

        await sendVerificationCodeRequest(
            email,
            () => toast.success("Code sent to your email."),
            () => reset()
        );
        hideLoading();
    };

    const reset = () => setFormState({ code: "", error: "", touched: false });

    const isSubmitButtonDisabled = () => !formState.code || formState.error;

    const submitAction = async (e) => {
        e.preventDefault();
        showLoading();

        if (isRegisterFlow) {
            try {
                const isVerified = await handleVerificationRequest(email, formState.code);
                if (!isVerified) return;
                await handleRegistrationRequest(name, email, password, navigate);
            } finally {
                reset();
                hideLoading();
            }
        } else {
            try {
                const data = await validateCode(email, formState.code);

                if (!data || !data.success) {
                    const errorMessage = data?.messages?.[0]?.value || 'Unknown error occurred';
                    toast.error(errorMessage);
                    return;
                }

                toast.success('Code successfully passed, now you can set your new password.');
                navigate(BASE_ROUTERS.newPassword, { state: { email } })
            } catch (error) {
                toast.error('Failed to log in, please try again later');
            } finally {
                reset();
                hideLoading();
            }
        }
    };

    return (
        <ConfirmCodePresenter
            isRegisterFlow={isRegisterFlow}
            formState={formState}
            setFormState={setFormState}
            submitAction={submitAction}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            handleSendVerificationCodeRequest={handleSendVerificationCodeRequest}
            email={email}
        />
    );
}

export default ConfirmCodeContainer;
