export const BASE_ROUTERS = {
    comingSoon: "/coming-soon",
    main: "/",
    login: "/login",
    register: "/register",
    forgotPassword: "/forgotPassword",
    newPassword: "/set-password",
    confirmCodeForRegister: "/confirm-code",
    confirmCodeForResetPassword: "/forgotPassword/confirm-code",
    aboutUs: "/about-us",
    eventCategories: "/event-categories",
    howToOrder: "/how-to-order",

    designsQueue: "/staff/queue",
    designsSales: "/staff/sales"
}