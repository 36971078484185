import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import FooterDesktop from "./FooterDesktop";
import {SCREEN_SIZES} from "../../main/constants/SCREEN_SIZES";
import {useScreenSize} from "../../main/providers/ScreenSizeProvider";
import FooterMobile from "./FooterMobile";
import {SOCIAL_MEDIA} from "../../main/constants/BASE_CONSTANTS";
import './style/footer.scss';

function Footer({ isAccountButtonShown = true }) {

    const {isSmallerThan} = useScreenSize();
    const location = useLocation()

    useEffect(() => {
        scrollToTop()
    }, [location]);

    function scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <>
            <footer className={"footer"}>
                {isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                    <FooterMobile socialMedia={SOCIAL_MEDIA}/>
                ) : (
                    <FooterDesktop socialMedia={SOCIAL_MEDIA}/>
                )}
            </footer>
        </>
    )

}

export default Footer