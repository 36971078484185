import * as amplitude from '@amplitude/analytics-browser';

// Pages viewed

export const trackScreenOpened = (screen) => {
    amplitude.track(screen);
};

// Header Events
export const trackAboutUsClicked = (screen) => {
    amplitude.track('about_us_clicked');
};

export const trackServiceClicked = (screen) => {
    amplitude.track('service_clicked');
};

export const trackHowToOrderClicked = (screen) => {
    amplitude.track('how_to_order_clicked', { screen });
};

export const trackContactUsClicked = (screen) => {
    amplitude.track('contact_us_clicked', { screen });
};

export const trackHeaderLogoClicked = (screen) => {
    amplitude.track('header_logo_clicked', { screen });
};

export const trackHeaderBurgerButtonClicked = (screen) => {
    amplitude.track('header_burger_button_clicked', { screen });
};


// Footer Events
export const trackFooterLogoClicked = (screen) => {
    amplitude.track('footer_logo_clicked', { screen });
};

export const trackFooterLanguageClicked = (screen, code) => {
    amplitude.track('footer_language_clicked', { screen, code });
};

export const trackFooterPaymentMethodClicked = (screen, method) => {
    amplitude.track('footer_payment_method_clicked', { screen, method });
};

export const trackFooterPhoneNumberClicked = (screen) => {
    amplitude.track('footer_phone_number_clicked', { screen });
};

export const trackFooterEmailClicked = (screen) => {
    amplitude.track('footer_email_clicked', { screen });
};

export const trackFooterSocialIconClicked = (screen, name) => {
    amplitude.track('footer_social_icon_clicked', { screen, name });
};

// Banner Events
export const trackBannerClicked = (category, enable) => {
    amplitude.track('banner_clicked', { category, enable });
};

// Category Events
export const trackCategoryClicked = (screen, name, enable) => {
    amplitude.track('category_clicked', { screen, name, enable });
};

export const trackCategoryArrowClicked = (screen) => {
    amplitude.track('category_arrow_clicked', { screen });
};

// Bestsellers Events
export const trackBestsellerClicked = (invitation) => {
    amplitude.track('bestseller_clicked', { invitation });
};

export const trackBestsellerArrowClicked = () => {
    amplitude.track('bestseller_arrow_clicked');
};

// catalog Events

export const trackCatalogItemClicked = (invitation) => {
    amplitude.track('catalog_item_clicked', { invitation });
};

// Order Invitation Events

export const trackOrderButtonClicked = (screen, invitation) => {
    amplitude.track('order_invitation_button_clicked', { screen, invitation });
};

export const orderingPopupPhoneNumberClicked = (screen) => {
    amplitude.track('ordering_popup_phone_number_clicked', { screen });
};

export const orderingPopupSocialIconClicked = (screen, name) => {
    amplitude.track('ordering_popup_social_icon_clicked', { screen, name });
};