export const BASE_IMAGES = {
    // auth component
    loginImage: '/assets/auth/login_image.svg',
    registerImage: '/assets/auth/register_image.svg',
    forgotPasswordImage: '/assets/auth/forgot_password_image.svg',
    showPasswordIcon: '/assets/auth/icons/show_password_icon.svg',
    hidePasswordIcon: '/assets/auth/icons/hide_password_icon.svg',

    // logo
    logoIcon: '/assets/logo/hyoor_logo.svg',
    logoIconText: '/assets/logo/logo_text.svg',

    // banner
    weddingImage: '/assets/banner/wedding_image.jpg',
    birthdayImage: '/assets/banner/birthday_image.jpg',
    engagementImage: '/assets/banner/engagement_image.jpg',
    baptismImage: '/assets/banner/baptism_image.jpg',
    firstTooth: '/assets/banner/first_tooth_image.jpg',
    bannerArrowIcon: '/assets/banner/banner_arrow_icon.svg',

    // contact-icons, social-icons, payment-icons
    facebookNativeIcon: '/assets/social-icons/facebook_native.svg',
    instagramNativeIcon: '/assets/social-icons/instagram_native.svg',
    whatsappNativeIcon: '/assets/social-icons/whatsapp_native.svg',
    viberNativeIcon: '/assets/social-icons/viber_native.svg',
    telegramNativeIcon: '/assets/social-icons/telegram_native.svg',

    facebookIcon: '/assets/social-icons/facebook.svg',
    instagramIcon: '/assets/social-icons/instagram.svg',
    whatsappIcon: '/assets/social-icons/whatsapp.svg',
    tiktokIcon: '/assets/social-icons/tiktok.svg',
    youtubeIcon: '/assets/social-icons/youtube.svg',
    pinterestIcon: '/assets/social-icons/pinterest.svg',

    emailIcon: '/assets/contact-icons/email.svg',
    phoneIcon: '/assets/contact-icons/phone.svg',
    amexIcon: '/assets/payment-icons/amex.svg',
    arcaIcon: '/assets/payment-icons/arca.svg',
    easyPayIcon: '/assets/payment-icons/easypay.svg',
    idramIcon: '/assets/payment-icons/idram.svg',
    mastercardIcon: '/assets/payment-icons/mastercard.svg',
    telcellIcon: '/assets/payment-icons/telcell.svg',
    visaIcon: '/assets/payment-icons/visa.svg',

    // catalog
    w201: '/assets/catalog/w_201.jpg',
    w202: '/assets/catalog/w_202.jpg',
    w203: '/assets/catalog/w_203.jpg',
    w204: '/assets/catalog/w_204.jpg',
    w205: '/assets/catalog/w_205.jpg',
    w206: '/assets/catalog/w_206.jpg',
    w207: '/assets/catalog/w_207.jpg',
    w208: '/assets/catalog/w_208.jpg',
    w209: '/assets/catalog/w_209.jpg',

    // order-invitation invitation
    orderPopupBoy: '/assets/order-invitation/order-popup-boy.png',
    orderPopupGirl: '/assets/order-invitation/order-popup-girl.png',

    // categories
    weddingCategory: '/assets/categories/wedding_category.jpg',
    engagementCategory: '/assets/categories/engagement_category.jpg',
    birthdayCategory: '/assets/categories/birthday_category.jpg',
    baptismCategory: '/assets/categories/baptism_category.jpg',
    firstToothCategory: '/assets/categories/first_tooth_category.jpg',

    // how to order
    howToOrderCallToOperator: '/assets/how-to-order/how_to_order_call_to_operator.svg',
    howToOrderChoosing: '/assets/how-to-order/how_to_order_choosing.svg',
    howToOrderMakeYourself: '/assets/how-to-order/how_to_order_make_yourself.svg',
    howToOrderProcessing: '/assets/how-to-order/how_to_order_processing.svg',
    howToOrderResult: '/assets/how-to-order/how_to_order_result.svg',

    rightArrowIcon: '/assets/icons/right_arrow_icon.svg',
    leftArrowIcon: '/assets/left-arrow.svg',
    downArrowIcon: '/assets/icons/down_arrow_icon.svg',
    sortingIcon: '/assets/icons/sorting_icon.svg',
    filterIcon: '/assets/icons/filter_icon.svg',

    xIcon: '/assets/icons/close_icon.svg',
    burgerIcon: '/assets/icons/burger_icon.svg',
    accountUnregisteredIcon: '/assets/icons/account_unregistered_icon.svg',
    accountRegisteredIcon: '/assets/icons/account_registered_icon.svg',
    checkoutIcon: '/assets/icons/checkout_icon.svg',
    favoriteIcon: '/assets/icons/fav_icon.svg',
    signOutIcon: '/assets/icons/sign_out_icon.svg',

    customizingTemplateImage: '/assets/invitations/customizing_template_image.svg',

    // coming soon
    comingSoonImage: '/assets/coming-soon/coming_soon.svg',
    comingSoonMainImage: '/assets/coming-soon/coming_soon_main_image.svg',
    comingSoonTextImage: '/assets/coming-soon/coming_soon_text_image.svg',

    // not found
    notFound: '/assets/not-found/not_found.svg',

    aboutUsImage: '/assets/about-us/about_us_image.svg',
    orderChoosing: '/assets/how-to-order-page/choosing.svg',
    orderByCallingOperator: '/assets/how-to-order-page/call_to_operator.svg',
    orderProcessing: '/assets/how-to-order-page/processing.svg',
    orderResult: '/assets/how-to-order-page/result.svg',

    infoCircleIcon: '/assets/invitations/icons/info_circle_icon.svg',
}