export const SCREEN_SIZES = {
    DESKTOP_L: {
        title: "--desktopL",
        value: 1440
    },
    DESKTOP_M: {
        title: "--desktopM",
        value: 1280
    },
    TABLET_L: {
        title: "--tabletL",
        value: 1024
    },
    TABLET_M: {
        title: "--tabletM",
        value: 834
    },
    MOBILE_L: {
        title: "--mobileL",
        value: 440
    },
    MOBILE_M: {
        title: "--mobileM",
        value: 414
    },
    MOBILE_S: {
        title: "--mobileS",
        value: 360
    }
}