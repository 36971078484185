import React from "react";
import {motion} from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import SidebarMenu from "./SidebarMenu";
import SidebarOverlayContent from "./SidebarOverlayContent";

function Sidebar({ sidebar, showSidebar, hideSidebar }) {

    return (
        <>
            <div className={"sidebar"}>
                <SidebarOverlayContent sidebar={sidebar} showSidebar={showSidebar}/>

                <motion.div className={"sidebar-content"}
                            initial={{x: '100%'}}
                            animate={{x: sidebar ? '0%' : '100%'}}
                            transition={{duration: 0.4, ease: 'easeInOut'}}>

                    <OutsideClickHandler onOutsideClick={() => hideSidebar()}>
                        <div className={"menu"}>
                            <div className={"x-icon-container"} onClick={() => hideSidebar()}>
                                <img src={BASE_IMAGES.xIcon} className={"x-icon"} alt="" title="X Icon"/>
                            </div>

                            <SidebarMenu hideSidebar={hideSidebar}/>
                        </div>
                    </OutsideClickHandler>

                </motion.div>

                <div className={"header-mobile-height"}/>
            </div>

            <style jsx={"true"}>{`
              .sidebar-overlay {
                pointer-events: ${sidebar ? 'auto' : 'none'};
              }
            `}</style>
        </>
    );
}

export default Sidebar;
