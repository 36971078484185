import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import FooterLanguagesSection from "./FooterLanguagesSection";
import {trackFooterPaymentMethodClicked} from "../../../main/utility/analytics";
import {useLocation} from "react-router-dom";
import {getRouterPrefix} from "../../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../../main/constants/BASE_ROUTERS";

function FooterPaymentSection() {

    const { t } = useTranslation()
    const location = useLocation()
    const paymentMethods = [
        {
            name: "master",
            icon: BASE_IMAGES.mastercardIcon
        },
        {
            name: "visa",
            icon: BASE_IMAGES.visaIcon
        },
        {
            name: "amex",
            icon: BASE_IMAGES.amexIcon
        }
    ]

    function paymentMethodClickedHandler(paymentMethodName) {
        trackFooterPaymentMethodClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname,
            paymentMethodName
        )
    }

    return (
        <div className="footer-payment-section">

            <div className="footer-payment-section-container">
                <div>
                    <h2 className={"footer-section-title"}>{t("payment-methods")}</h2>
                    <div className={"footer-payment-icons"}>{paymentMethods.map((item, index) => {
                        return (
                            <button key={index} onClick={() => { paymentMethodClickedHandler(item.name) }}>
                                <img src={item.icon} className={"footer-payment-icon"}/>
                            </button>
                        )
                    })}</div>
                </div>

                <FooterLanguagesSection/>
            </div>

        </div>
    )
}

export default FooterPaymentSection