import { NavLink } from "react-router-dom";
import {BASE_ROUTERS} from "../../../../../main/constants/BASE_ROUTERS";
import Lottie from "lottie-react";
import emailLottie from "../../../../../main/lottie/email/hyoor_email.json";

function ConfirmCodePresenter(
    {
        isRegisterFlow,
        formState,
        setFormState,
        submitAction,
        isSubmitButtonDisabled,
        handleSendVerificationCodeRequest,
        email,
    }
) {
    return (
        <>
            <div className="auth">
                <div className="form-container">
                    <h1 className="title">CONFIRMATION</h1>
                    <p className="confirmation-info">
                        Enter the confirmation code sent to {` ${email}`}
                        <span className="auth-text-button" onClick={handleSendVerificationCodeRequest}>
                        Resend Code
                    </span>
                    </p>
                    <form className="form" onSubmit={submitAction}>
                        <div className={"form-inputs"}>
                            <div>
                                <input
                                    className="auth-input"
                                    placeholder="Confirmation Code"
                                    value={formState.code}
                                    onChange={(e) =>
                                        setFormState({ ...formState, code: e.target.value, touched: true })
                                    }
                                />
                                {formState.touched && formState.error && <p className="error">{formState.error}</p>}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="auth-button"
                            aria-disabled={isSubmitButtonDisabled()}
                            disabled={isSubmitButtonDisabled()}
                        >
                            {isRegisterFlow ? "FINISH" : "SUBMIT"}
                        </button>
                    </form>
                    {isRegisterFlow && (
                        <div className="sign-up-info">
                            <div className="text-with-lines">
                                <p>or</p>
                            </div>
                            <p className="register-info">
                                Have an account?{" "}
                                <NavLink to={BASE_ROUTERS.login}>
                                    <span className="auth-text-button">LOGIN</span>
                                </NavLink>
                            </p>
                        </div>
                    )}
                </div>
                <div className="lottie-container">
                    <Lottie animationData={emailLottie} loop autoPlay className="email-lottie" />
                </div>
            </div>
        </>
    );
}

export default ConfirmCodePresenter;
