import {BASE_URL} from "../../BASE_API";

export async function registerUser(name, email, password) {
    try {
        const response = await fetch(`${BASE_URL}/User/Register`, {
            method: 'POST',
            headers: {
                'accept': 'text/plain',
                'Content-Type': 'application/json-patch+json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                name: name,
            }),
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error during registration:', error.message);
    }
}