import {BASE_IMAGES} from "./BASE_IMAGES";

const phoneNumber = '(+374) 33 21 12 33';
const phoneNumberRaw = phoneNumber.replace(/[^0-9]/g, ''); // Extracts only numbers

export const BASE_CONSTANTS = {
    phoneNumber: phoneNumber,
    email: 'hyoor.am@gmail.com',

    facebook: "https://www.facebook.com/hyoor.official",
    instagram: "https://www.instagram.com/hyoor_official",
    whatsapp: `https://wa.me/${phoneNumberRaw}`,
    viber: `viber://chat?number=%2B${phoneNumberRaw}`,
    telegram: "https://t.me/hyoor_official",
};


export const SOCIAL_MEDIA = [
    {
        name: "facebook",
        icon: BASE_IMAGES.facebookIcon,
        url: "https://www.facebook.com/hyoor.official"
    },
    {
        name: "instagram",
        icon: BASE_IMAGES.instagramIcon,
        url: "https://www.instagram.com/hyoor_official"
    },
    {
        name: "tiktok",
        icon: BASE_IMAGES.tiktokIcon,
        url: "https://www.tiktok.com/@hyoor_official?_t=ZS-8th381HRdmq&_r=1"
    },
    {
        name: "pinterest",
        icon: BASE_IMAGES.pinterestIcon,
        url: "https://www.pinterest.com/hyoor_official"
    },
]