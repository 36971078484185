import {SECTION} from "../../../main/constants/SECTION";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import {BASE_CONSTANTS} from "../../../main/constants/BASE_CONSTANTS";
import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {
    trackFooterEmailClicked,
    trackFooterPaymentMethodClicked,
    trackFooterPhoneNumberClicked, trackFooterSocialIconClicked
} from "../../../main/utility/analytics";
import {getRouterPrefix} from "../../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../../main/constants/BASE_ROUTERS";

function FooterContactsSection({ socialMedia }) {

    const { t } = useTranslation()
    const location = useLocation()

    function phoneNumberClickedHandler() {
        trackFooterPhoneNumberClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        )
    }

    function emailClickedHandler() {
        trackFooterEmailClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname
        )
    }

    function socialIconClickedHandler(mediaItem) {
        trackFooterSocialIconClicked(
            location.pathname === getRouterPrefix() + BASE_ROUTERS.main ? "Home Screen" : location.pathname,
            mediaItem.name
        )
    }

    return (
        <div className="footer-contacts-section" id={SECTION.contactUs.id}>

            <div className="footer-contacts-section-container">
                <div>
                    <h2 className={"footer-section-title"}>{t("contact-us")}</h2>

                    <div className={"footer-contact-infos-container"}>
                        <a href={`tel:${BASE_CONSTANTS.phoneNumber}`} className={"footer-contact"}>
                            <img src={BASE_IMAGES.phoneIcon} className={"footer-contact-icon"}/>
                            <h2 className={"footer-contact-info"} onClick={phoneNumberClickedHandler}>{BASE_CONSTANTS.phoneNumber}</h2>
                        </a>
                        <a href={`mailto:${BASE_CONSTANTS.email}`} className={"footer-contact"}>
                            <img src={BASE_IMAGES.emailIcon} className={"footer-contact-icon"}/>
                            <h2 className={"footer-contact-info"} onClick={emailClickedHandler}>{BASE_CONSTANTS.email}</h2>
                        </a>
                    </div>
                </div>

                <div>
                    <h2 className={"footer-section-title"}>{t("follow-us")}</h2>
                    <div className={"footer-social-icons"}>{socialMedia.map(((item, index) => {
                        return (
                            <Link key={index}
                                  to={item.url}
                                  draggable={false}
                                  onClick={() => { socialIconClickedHandler(item) }}
                                  target={"_blank"}
                                  rel="noopener noreferrer">
                                <img key={uuid()} src={item.icon} className={"footer-social-icon"}/>
                            </Link>
                        )
                    }))}</div>
                </div>
            </div>

        </div>
    )
}

export default FooterContactsSection