export const SECTION = {
    aboutUs: {
        id: "about-us-section",
        title: "about-us",
        urlName: "about-us"
    },
    eventCategories: {
        id: "event-categories-section",
        title: "services",
        urlName: "event-categories"
    },
    howToOrder: {
        id: "how-to-order-section",
        title: "how-to-order",
        urlName: "how-to-order"
    },
    contactUs: {
        id: "contact-us-section",
        title: "contact-us",
        urlName: "contact-us"
    },
    bestSellers: {
        id: "best-sellers-section",
        title: "bestSellers",
        urlName: "bestSellers"
    }
}