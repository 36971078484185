import {motion} from "framer-motion";
import React from "react";

function SidebarOverlayContent({ sidebar, showSidebar }) {

    return (
        <motion.div className={"sidebar-overlay"}
                    initial={{opacity: 0}}
                    animate={{opacity: sidebar ? 1 : 0}}
                    transition={{duration: 0.4, ease: 'easeInOut'}}
                    onClick={showSidebar}
        />
    )
}

export default SidebarOverlayContent