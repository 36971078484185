import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SidebarData} from "../models/SidebarData";
import {SECTION} from "../../../main/constants/SECTION";
import {getRouterPrefix} from "../../router/getRouterPrefix";
import {isMatchingRoute, scrollToSection} from "../../../main/utility/utility";
import {BASE_ROUTERS} from "../../../main/constants/BASE_ROUTERS";
import {
    trackAboutUsClicked,
    trackContactUsClicked,
    trackHowToOrderClicked,
    trackServiceClicked
} from "../../../main/utility/analytics";

function SidebarMenu({ hideSidebar }) {

    const { t } = useTranslation()
    const isMainRoute = isMatchingRoute(BASE_ROUTERS.main)

    function handler(item) {
        hideSidebar();
        scrollToSection(item.key)
        trackAnalytics(item.title)
    }

    function trackAnalytics(sectionTitle, pathName = "Home Screen") {
        switch (sectionTitle) {
            case SECTION.aboutUs.title:
                trackAboutUsClicked(pathName);
                break;
            case SECTION.eventCategories.title:
                trackServiceClicked(pathName);
                break;
            case SECTION.howToOrder.title:
                trackHowToOrderClicked(pathName);
                break;
            case SECTION.contactUs.title:
                trackContactUsClicked(pathName);
                break;
            default:
                break
        }
    }

    return (
        <div className={"menu-items"}>
            {SidebarData.map((item, index) => (
                isMainRoute || item.title === SECTION.contactUs.title ? (
                    <h2 key={index}
                        className={"menu-item"}
                        onClick={() => {
                            handler(item)
                        }}>
                        {t(item.title)}
                    </h2>
                ) : (
                    <Link key={index}
                          to={`${getRouterPrefix()}${item.urlName}`}
                          onClick={() => { trackAnalytics(item.title, item.urlName) } }
                          className={"menu-item"}>
                        <h2>{t(item.title)}</h2>
                    </Link>
                )
            ))}
        </div>
    );
};

export default SidebarMenu;
