import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from "./components/pages/App";
import './main/services/i18n';

import './styles/index.scss';
import './styles/main.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as amplitude from '@amplitude/analytics-browser';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App/>
);

amplitude.init('7ce38beb5b4c1c0531be9f9f8ff88824', {
    serverZone: 'EU',
    defaultTracking: false,
    autocapture: false
});

reportWebVitals();
