import './custom-components/loading/styles/loading.scss'
import animationData from "../main/lottie/loading/hyoor_loading.json";
import { useLoading } from "../main/providers/LoadingProvider";
import Lottie from "lottie-react";

function Loading() {

    const { isLoading } = useLoading();

    if (!isLoading) return null;

    return (
        <>
            <section className={"loading"}>
                <Lottie animationData={animationData} className={"lottie"}/>
            </section>
            <style jsx={"true"}>{`
                .loading {
                  min-height: 100vh;
                }
            `}</style>
        </>
    )
}

export default Loading